// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import CKEditor from '@ckeditor/ckeditor5-vue'
import CoreuiVueCharts from '@coreui/vue-chartjs'
import axios from 'axios'
import BootstrapVue from 'bootstrap-vue'
import 'core-js/es/array'
import 'core-js/es/promise'
import 'core-js/es/string'
import iziToast from 'izitoast'
// setTimeout(()=>{
//   axios.get('/api/get/user').then(response=>{
//     Vue.prototype.$user = response.data
//   })
// },100)
import moment from 'moment'
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
// import VueCloneya from 'vue-cloneya'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import Multiselect from 'vue-multiselect'
import VueSweetalert2 from 'vue-sweetalert2'
import App from './App'
import Auth from './packages/Auth'
import router from './router'
import store from "./store/index"
// Vue.use(VueCloneya)



require('./assets/css/style.css');


// import JsonExcel from 'vue-json-excel'
// import jsPDF from 'jspdf';
Vue.component('multiselect', Multiselect)
Vue.use( CKEditor );
Vue.use(CoreuiVueCharts)
Vue.use(BootstrapVue,iziToast,Auth)
Vue.prototype.$iziToast = iziToast
Vue.prototype.$user = {type:0}




Vue.use(VueSweetalert2);

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

//axios.defaults.baseURL = 'http://nishutiapi.bemantech.com'
// axios.defaults.baseURL = 'http://apidemo.rubelgroup.com.bd'
//axios.defaults.baseURL = 'http://testapi.bill-chandpur.paurasheba.com'
axios.defaults.baseURL = 'https://billapi-chandpur.paurasheba.com'
//axios.defaults.baseURL = 'http://localhost:8000'
axios.defaults.headers.common["Authorization"] = 'Bearer '+ Vue.auth.getToken()

Vue.prototype.$user = Vue.auth.getUser()

Vue.prototype.$axios = axios

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
});
Vue.filter('formatAppDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD-MM-YYYY')
  }
});

router.beforeEach(
  (to,from,next) => {
    if(to.matched.some(record => record.meta.forVisitors)){
      if(Vue.auth.isAuthenticated())
        next({
          path: '/dashboard'
        })
      else
        next()
    }

    else if(to.matched.some(record => record.meta.forAuth)){
      if(!Vue.auth.isAuthenticated())
      next({
        path: '/login'
      })
      else
        next()
    }

    else
      next()
  }
)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App
  }
})
